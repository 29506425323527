@use 'sass:color';
@use 'variables';
@use '@ng-select/ng-select/scss/default.theme' as ng-select;

@tailwind base;
@tailwind components;
@tailwind utilities; /* You can add global styles to this file, and also import other style files */

.ng-select {
  border: none;
  padding: 0;
}

.ls-invalid {
  .ng-select {
    .ng-select-container,
    .ng-dropdown-panel {
      border-color: rgb(240, 82, 82, 1);
      background-color: rgb(253, 242, 242, 1);

      &:focus {
        outline-width: 1;
        outline-color: rgb(240, 82, 82, 1);
      }

      .ng-option:not(.ng-option-marked) {
        background-color: rgb(253, 242, 242, 1);
      }

      .ng-option.ng-option-marked {
        background-color: color.adjust(rgb(253, 242, 242, 1), $lightness: -10%);
      }
    }

    &.ng-select-focused {
      &:not(.ng-select-opened) > .ng-select-container {
        border-color: ng-select.$ng-select-highlight;
        box-shadow: ng-select.$ng-select-box-shadow;
      }
    }

    &.ng-select-focused {
      &:not(.ng-select-opened) > .ng-select-container {
        outline-color: rgb(240, 82, 82, 1);
        border-color: rgb(240, 82, 82, 1);
        border-width: 2px;
        box-shadow: none;
      }
    }
  }
}

.ng-select.ng-select-disabled {
  opacity: 0.5;

  .ng-arrow-wrapper {
    visibility: hidden;
  }
}

.ng-select .ng-placeholder {
  color: rgb(179, 179, 179);
}

html {
  font-size: 16px;
}
